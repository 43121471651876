.search-results {
    margin: -1rem 0 0 0;
    transform: translate(0, -0.5rem);
    opacity: 0;
    transition: transform @trans-fast, opacity @trans-fast;

    &.___is-visible {
        transform: translate(0, 0);
        opacity: 1;
    }
}

//
// Search - Result
//

.search-result {
    display: block;
    position: relative;
    padding: 0 0 0.5rem 1.6rem;
    color: #000;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    .icon('chevron-right');

    &:before {
        position: absolute;
        top: 1rem; left: 0;
        color: @color-primary;
        font-size: 0.9rem;
        transition: @trans-fast;
    }

    &:hover:before {
        left: 0.4rem;
    }
}
