.account-overlay {
  display: flex;
  align-items: stretch;
  background-color: @color-grey__20;
  height: 100%;

  @media @screen-md-max {
    flex-direction: column;
    height: auto;
    min-height: 100%;
  }

  &.___profile {
    min-height: 100%;
    height: auto;

    @media @screen-lg-max {
      flex-direction: column;
    }
  }
}

.account,
.account-theme {
  padding: 6rem 8rem 4rem;

  @media @screen-lg-max {
    padding: 4rem 4rem 2rem;
  }

  @media @screen-md-max {
    padding: 4rem 8rem 4rem;
  }

  @media @screen-sm-max {
    padding: 4rem 4rem 2rem;
  }

  @media @screen-xs-max {
    padding: 3.2rem 2.4rem;
  }
}
.account--terms {
  font-size: 1.4rem;
  max-width: max_content;
  color: @color-black__60;
}

.account {
  flex: 0 1 50%;
  max-width: 60rem;
  display: flex;
  order: 1;
  flex-direction: column;
  color: @color-black__60;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: @color-white;
  box-shadow: 0 0 24rem rgba(0, 0, 0, 0.15);

  @media @screen-md-max {
    flex: 1 0 auto;
    max-width: none;
    min-height: 0;
    height: auto;
    order: 2;
  }
}

.account-theme {
  flex: 1 1 auto;
  order: 2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;

  @media @screen-md-max {
    flex: 0 0 50vw;
    order: 1;
  }
}

.account-overlay:not(.___profile) .account__top-bar {
  margin-bottom: 4rem;

  @media @screen-md-max {
    display: none;
  }
}

@media @screen-lg-max {
  body.show-menu {
    .account-menu__show {
      display: none;
    }
  }

  body:not(.show-menu) {
    .account-menu__list {
      display: none;
    }

    .account__top-bar {
      margin-bottom: 0;
    }

    .account-menu__hide {
      display: none;
    }
  }
}

.account__close-wrapper {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.account__close {
  .icon(chevron-left);

  &:before {
    font-size: 0.6em;
    vertical-align: baseline;
    margin-right: 0.6em;
    color: @color-grey__50;
  }

  &:hover:before {
    color: @color-grey;
  }

  @media @screen-sm-max {
    font-size: 1.6rem;
  }
}

.account__slider {
  display: flex;
  flex-grow: 1;
  flex-shrink: 0;
  position: relative;
  width: 200%;
  transition: transform @trans-normal;

  .___register & {
    transform: translate(-50%, 0);
  }

  @media @screen-sm {
    align-items: center;
  }
}

.account__content-container {
  width: 50%;
}

.account__content {
  fieldset:not(.loginview) {
    margin-top: 5rem;
  }
}

.account__content_width {
  width: 100%;
  max-width: max_content;
  margin: 0 auto;
  padding: 4rem;
  background-color: #fff;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2);

  @media @screen-sm-max {
    padding: 4rem 0 2rem 0;
  }
}

.login__step-title {
  margin-bottom: 2.4rem;
  display: flex;

  div {
    flex: 1 1 auto;
  }

  .login__step-icon {
    width: 3.5rem;
    height: 3.5rem;
    display: none;

    @media @screen-md-max {
      display: block;
    }
  }

  h2 {
    color: @color-black;
  }
}

.forgot-password {
  margin-top: 0.75rem;
  display: block;
  text-align: right;
}

.password__toggle {
  position: absolute;
  right: 0;
  top: 0;
  height: 4.8rem;
  padding: 0.8rem 0.8rem 0.6rem;
  cursor: pointer;
  appearance: none;
  background: none !important;
  border: none;
  margin: 0;
  display: flex;
  align-items: center;

  > * {
    display: flex;
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;
  }

  &:hover svg {
    fill: @color-black;
  }
}

.password__toggle-on {
  display: none;

  svg {
    fill: @color-primary !important;
  }
}

.password__toggle-off {
  svg {
    fill: @color-icon-grey;
  }
}

input[type="text"] + .password__toggle .password__toggle-off {
  display: none;
}
input[type="text"] + .password__toggle .password__toggle-on {
  display: flex;
}

.login__buttons,
.register__buttons {
  margin: 1.6rem 0 4rem 0;

  &.--small-margin {
    margin-bottom: 1.6rem;
  }
}

.login__check,
.register__check {
  line-height: 4rem;

  a {
    color: @color-primary;
    text-decoration: underline;
  }
}

.errorlist {
  color: @color-red;
}

.capslock {
  float: right;
  color: @color-red;
}

.message_success {
  color: @color-blue;
}

.account-menu,
.account-right {
  padding: 4rem 0;

  @media @screen-lg-max {
    padding: 3.2rem 0;
  }
  @media @screen-xs-max {
    padding: 2.4rem 0;
  }
}

.account-menu {
  flex: 0 1 60%;
  max-width: 50rem;
  display: flex;
  order: 1;
  flex-direction: column;
  color: @color-black__60;
  min-height: 100%;
  overflow: hidden;
  background-color: @color-white;
  box-shadow: 0 0 24rem rgba(0, 0, 0, 0.15);

  @media @screen-lg-max {
    flex: 0 0 auto;
    max-width: none;
    min-height: 0;
    order: 2;
  }
}

.account-menu__hamburger {
  width: 40px;
  height: 40px;
  justify-content: center;
  align-items: center;
  display: none;
  cursor: pointer;
  border-radius: 50%;
  border: none;
  background: none;
  padding: 0;

  @media @screen-lg-max {
    display: flex;
  }

  svg {
    fill: black;
  }

  &:focus {
    background-color: transparent;
  }

  &:hover {
    background-color: @color-hover;
  }
  &:active {
    background-color: @color-active;
  }
}

.account-right {
  flex: 1 1 auto;
  order: 2;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  padding-left: 4rem;
  padding-right: 4rem;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  .account-right__content {
    max-width: 100rem;
    padding-top: 4.5rem;

    @media @screen-lg-max {
      padding-top: 1rem;
    }
  }

  @media @screen-sm-max {
    padding-left: 2.4rem;
    padding-right: 2.4rem;
  }
}

.account-menu .account__top-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
}

.account-menu .account__top-bar,
.account-menu__list a {
  padding-left: 6rem;
  padding-right: 4rem;

  @media @screen-sm-max {
    padding-left: 3rem;
    padding-right: 2.4rem;
  }
}

.account-menu__list {
  li:first-child {
    border-bottom: 1px solid @color-divider !important;
  }

  li:not(:last-child) {
    border-bottom: 1px solid @color-grey__30;
  }

  li {
    display: block;
    position: relative;

    .account-menu__list-item {
      display: flex;
      padding-top: 1.4rem;
      padding-bottom: 1.4rem;
      align-items: center;
      padding-left: 6rem;
      padding-right: 4rem;

      @media @screen-sm-max {
        padding-left: 3rem;
        padding-right: 2.4rem;
      }

      h2 {
        padding-right: 1.2rem;
      }
    }

    a {
      color: @color-black__60;
      display: flex;
      padding-top: 1.4rem;
      padding-bottom: 1.4rem;
      align-items: center;

      &:hover {
        background-color: @color-hover;
      }
      &:active {
        background-color: @color-active;
      }

      &.current {
        color: @color-white;
        background-color: @color-primary !important;

        .title {
          color: @color-white;
        }

        svg {
          fill: @color-white;
        }
      }
    }

    svg {
      flex-shrink: 0;
      fill: @color-primary;
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 2rem;
    }

    .title {
      font-size: 1.8rem;
      color: @color-black;
    }
  }
}

.account-user__pickavatar {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 1.2rem;

  .input-field {
    flex: 0 0 8rem;
    margin: 0;

    @media @screen-md-max {
      flex-basis: 6rem;
    }
  }
  .button {
    flex: 0 1 auto;
  }
  #id_avatar {
    position: absolute;
    z-index: -1;
  }
}

.account-menu__avatar,
.account-user__avatar {
  border-radius: 50%;
  overflow: hidden;
  width: 5.6rem;
  height: 5.6rem;
  margin-left: auto;
}
.account-user__avatar {
  width: 8rem;
  height: 8rem;
  border-width: 0.2rem;
  display: block;
  margin-left: 0;
  background-color: @color-grey__20;

  @media @screen-md-max {
    width: 6rem;
    height: 6rem;
  }
}

.account__cards {
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  margin: 2rem -1rem;

  @media @screen-sm-max {
    margin: 2rem 0;
  }

  .account__cards {
    margin: 0;
  }

  .card {
    margin-bottom: 2rem;
    max-height: none;
    max-width: 40rem;

    &.___stretch {
      max-width: 82rem;
    }
  }

  .___fullspan {
    max-width: 82rem;
  }
}

.table {
  &.___stretch {
    width: 100%;
  }

  td,
  th {
    padding: 0;
    padding-right: 2.4rem;
  }

  td:last-child,
  th:last-child {
    padding-right: 0;
    text-align: right;
  }

  th {
    color: @color-black__60;
    font-weight: normal;
  }

  tbody tr:not(:last-child) {
    border-bottom: 1px solid @color-grey__30;
  }

  .button {
    margin: 0;
  }
}

.table__overflow {
  width: 100%;
  overflow-x: auto;
}

.image__2fa {
  float: right;
  width: 10rem;
  height: 10rem;
  margin: -1rem 0 0 2.4rem;
  text-align: center;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: @color-grey__20;

  svg {
    width: 75%;
  }
  &.__enable .icon-lock {
    fill: @color-red;
  }
  &.__disable .icon-lock {
    fill: @color-green;
  }
}

.loginview {
  display: none;
  animation-fill-mode: both;
  animation-duration: 0.5s;
  animation-name: fadeInDown;

  &.___active {
    display: block;
  }
}
.loginview__email:not(.___active) {
  display: none;
}
.loginview__saml .button,
.loginview__connect .button {
  font-size: 1.6rem;
}

[type="checkbox"] + label:before,
[type="checkbox"]:not(.filled-in) + label:after {
  width: 16px;
  height: 16px;
  border-color: #949494;
  border-radius: 3px;
}
[type="checkbox"] + label {
  padding-left: 2.5rem;
}

[type="checkbox"]:checked + label:before {
  top: -2px;
  left: -5px;
  width: 12px;
  height: 18px;
  border-right: 3px solid @color-green;
  border-bottom: 3px solid @color-green;
}

[type="checkbox"]:not(:checked) + label:hover:before {
  background-color: @color-hover;
}

[type="checkbox"]:not(:checked) + label:active:before {
  background-color: @color-active;
}

.hide-visually {
  display: inline-block;
  position: absolute;
  overflow: hidden;
  clip: rect(0 0 0 0);
  height: 1px;
  width: 1px;
  margin: -1px;
  padding: 0;
  border: 0;
  pointer-events: none;
}

.skip-navigation {
  &:focus,
  &:active {
    top: 4px;
    left: 4px;
    width: auto;
    height: 40px;

    margin: 0;
    padding: 0 12px;
    background-color: @color-primary;

    font-size: 14px;
    line-height: 40px;
    font-weight: 400;
    color: white;

    clip: auto;
    z-index: 14;

    outline-offset: -2px;
    outline-color: black;
  }
}

body.focusVisible *:not(input):focus {
  outline-offset: -2px;
  outline: 2px solid black;
}

@-webkit-keyframes fadeInDown {
  0% {
    opacity: 0;
    -webkit-transform: translateY(-1.25rem);
  }
  70% {
    opacity: 1;
  }
  100% {
    -webkit-transform: translateY(0);
  }
}
@keyframes fadeInDown {
  0% {
    opacity: 0;
    transform: translateY(-1.25rem);
  }
  70% {
    opacity: 1;
  }
  100% {
    transform: translateY(0);
  }
}
