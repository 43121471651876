#root {
    height: 100%;
    width: 100%;
}

fieldset {
    padding: 0;
    margin: 0;
    border: 0;
}

.main {
    overflow-x: auto;
}

.slide {
    overflow: auto;
}

.disc {
    list-style: disc;
    padding: inherit;
}

.__uppercase { text-transform: uppercase; }
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: color 9999s ease-out, background-color 9999s ease-in-out;
}

.elgg-system-messages {
    position: fixed;
    top: 24px;
    right: 20px;
    max-width: 500px;
    z-index: 2000;
}

.elgg-system-messages li {
    margin-top: 10px;
}

.elgg-system-messages li p {
    margin: 0;
}

.elgg-message {
    margin-top: 5px;
    margin-bottom: 5px;
    border: 1px solid;
    font-weight: bold;
    display: block;
    padding: 10px;
    cursor: pointer;
}
.elgg-state-success {
    background: #e6efc2;
    border-color: #c6d880;
    color: #264409;
}
.elgg-state-error {
    background: #fbe3e4;
    border-color: #fbc2c4;
    color: #8a1f11;
}
.elgg-state-notice {
    background-color: #4690D6;
}

.messages {
    background-color: white;
    box-shadow: inset 0 0 0 1px @color-grey__40;
    border-radius: @border-radius;

    li {
        padding: 1.2rem 1.2rem 1.2rem 6rem;
        position: relative;
    }

    svg {
        fill: @color-primary;
        position: absolute;
        top: 1.8rem;
        left: 1.8rem;
        width: 2.4rem;
        height: 2.4rem;
    }

    &.error {
        box-shadow: inset 0 0 0 1px @color-red;

        svg {
            fill: @color-red;
        }
    }
    &.correct {
        box-shadow: inset 0 0 0 1px @color-green;

        svg {
            fill: @color-green;
        }
    }
    &.info {
        box-shadow: inset 0 0 0 1px @color-blue;

        svg {
            fill: @color-blue;
        }
    }
}
    .messages__link {
        display: block;
    }

.register__captcha {
    margin: 2rem 0 4rem 0;
}

#id_avatar {
    visibility: hidden;
    width: 0;
    height: 0;
}

.navigation__logo svg {
    height: 3.5rem;
    transition: opacity @trans-fast;

    &:hover {
        opacity: 0.8;
    }
}

.___inline li {
    display: inline-block;
}
