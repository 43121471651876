.main {
    flex-grow: 1;
    background-color: @color-white;

    @media @screen-sm-max {
        .___sticky-nav & {
            padding-top: 9rem;
        }
    }

    @media @screen-sm {
        height: 100%;
        overflow-x: hidden;
    }
}
