// Grid
@gutter-width:                2rem;

// Column width
@width3: 83.333%;
@width4: 91.667%;
@width5: 66.667%;
@width6: 58.333%;
@width7: 16.666%;
@width8: 33.333%;
@width9: 41.667%;
@width10: 8.333%;
@width11: 100%;
@width12: 50%;
@width13: 25%;
@width14: 75%;

@container-width: 100.4rem + @gutter-width;
@gutter-compensation: (@gutter-width * 0.5) * -1;
@half-gutter-width: (@gutter-width * 0.5);
@outer-margin: 2rem;


.container-fluid, .container {
    margin-right: auto;
    margin-left: auto;
}

.container-fluid {
    padding-right: @outer-margin;
    padding-left: @outer-margin;
}

.container {
    margin-left: auto;
    margin-right: auto;
    max-width: @container-width;
    padding-left: @outer-margin;
    padding-right: @outer-margin;
}

.container-fluid {
    padding-left: @outer-margin;
    padding-right: @outer-margin;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    flex: 0 1 auto;
    margin-left: @gutter-compensation;
    margin-right: @gutter-compensation;
}

.row.reverse {
    flex-direction: row-reverse;
}

.col.reverse {
    flex-direction: column-reverse;
}

[class^="col-"] {
    flex: 0 0 auto;
    padding-left: @half-gutter-width;
    padding-right: @half-gutter-width;
    flex-basis: @width11;
    max-width: @width11;
}

.col-xs, .col-xs-1, .col-xs-2, .col-xs-3, .col-xs-4, .col-xs-5, .col-xs-6, .col-xs-7, .col-xs-8, .col-xs-9, .col-xs-10, .col-xs-11, .col-xs-12, .col-xs-offset-0, .col-xs-offset-1, .col-xs-offset-2, .col-xs-offset-3, .col-xs-offset-4, .col-xs-offset-5, .col-xs-offset-6, .col-xs-offset-7, .col-xs-offset-8, .col-xs-offset-9, .col-xs-offset-10, .col-xs-offset-11, .col-xs-offset-12  {
    flex: 0 0 auto;
    padding-left: @half-gutter-width;
    padding-right: @half-gutter-width;
}

.col-xs {
    flex-basis: 0;
    flex-grow: 1;
    max-width: @width11;
}

.col-xs-1 {
    flex-basis: @width10;
    max-width: @width10;
}

.col-xs-2 {
    flex-basis: @width7;
    max-width: @width7;
}

.col-xs-3 {
    flex-basis: @width13;
    max-width: @width13;
}

.col-xs-4 {
    flex-basis: @width8;
    max-width: @width8;
}

.col-xs-5 {
    flex-basis: @width9;
    max-width: @width9;
}

.col-xs-6 {
    flex-basis: @width12;
    max-width: @width12;
}

.col-xs-7 {
    flex-basis: @width6;
    max-width: @width6;
}

.col-xs-8 {
    flex-basis: @width5;
    max-width: @width5;
}

.col-xs-9 {
    flex-basis: @width14;
    max-width: @width14;
}

.col-xs-10 {
    flex-basis: @width3;
    max-width: @width3;
}

.col-xs-11 {
    flex-basis: @width4;
    max-width: @width4;
}

.col-xs-12 {
    flex-basis: @width11;
    max-width: @width11;
}

.col-xs-offset-0 {
    margin-left: 0;
}

.col-xs-offset-1 {
    margin-left: @width10;
}

.col-xs-offset-2 {
    margin-left: @width7;
}

.col-xs-offset-3 {
    margin-left: @width13;
}

.col-xs-offset-4 {
    margin-left: @width8;
}

.col-xs-offset-5 {
    margin-left: @width9;
}

.col-xs-offset-6 {
    margin-left: @width12;
}

.col-xs-offset-7 {
    margin-left: @width6;
}

.col-xs-offset-8 {
    margin-left: @width5;
}

.col-xs-offset-9 {
    margin-left: @width14;
}

.col-xs-offset-10 {
    margin-left: @width3;
}

.col-xs-offset-11 {
    margin-left: @width4;
}

.start-xs {
    display: flex;
    justify-content: flex-start;
    text-align: start;
}

.center-xs {
    display: flex;
    justify-content: center;
    text-align: center;
}

.end-xs {
    display: flex;
    justify-content: flex-end;
    text-align: end;
}

.top-xs {
    display: flex;
    align-items: flex-start;
}

.middle-xs {
    display: flex;
    align-items: center;
}

.bottom-xs {
    display: flex;
    align-items: flex-end;
}

.around-xs {
    display: flex;
    justify-content: space-around;
}

.between-xs {
    display: flex;
    justify-content: space-between;
}

.first-xs {
    display: flex;
    order: -1;
}

.last-xs {
    display: flex;
    order: 1;
}

@media @screen-sm {
    .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-offset-0, .col-sm-offset-1, .col-sm-offset-2, .col-sm-offset-3, .col-sm-offset-4, .col-sm-offset-5, .col-sm-offset-6, .col-sm-offset-7, .col-sm-offset-8, .col-sm-offset-9, .col-sm-offset-10, .col-sm-offset-11, .col-sm-offset-12  {
        flex: 0 0 auto;
        padding-left: @half-gutter-width;
        padding-right: @half-gutter-width;
    }

    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: @width11;
    }

    .col-sm-1 {
        flex-basis: @width10;
        max-width: @width10;
    }

    .col-sm-2 {
        flex-basis: @width7;
        max-width: @width7;
    }

    .col-sm-3 {
        flex-basis: @width13;
        max-width: @width13;
    }

    .col-sm-4 {
        flex-basis: @width8;
        max-width: @width8;
    }

    .col-sm-5 {
        flex-basis: @width9;
        max-width: @width9;
    }

    .col-sm-6 {
        flex-basis: @width12;
        max-width: @width12;
    }

    .col-sm-7 {
        flex-basis: @width6;
        max-width: @width6;
    }

    .col-sm-8 {
        flex-basis: @width5;
        max-width: @width5;
    }

    .col-sm-9 {
        flex-basis: @width14;
        max-width: @width14;
    }

    .col-sm-10 {
        flex-basis: @width3;
        max-width: @width3;
    }

    .col-sm-11 {
        flex-basis: @width4;
        max-width: @width4;
    }

    .col-sm-12 {
        flex-basis: @width11;
        max-width: @width11;
    }

    .col-sm-offset-0 {
        margin-left: 0;
    }

    .col-sm-offset-1 {
        margin-left: @width10;
    }

    .col-sm-offset-2 {
        margin-left: @width7;
    }

    .col-sm-offset-3 {
        margin-left: @width13;
    }

    .col-sm-offset-4 {
        margin-left: @width8;
    }

    .col-sm-offset-5 {
        margin-left: @width9;
    }

    .col-sm-offset-6 {
        margin-left: @width12;
    }

    .col-sm-offset-7 {
        margin-left: @width6;
    }

    .col-sm-offset-8 {
        margin-left: @width5;
    }

    .col-sm-offset-9 {
        margin-left: @width14;
    }

    .col-sm-offset-10 {
        margin-left: @width3;
    }

    .col-sm-offset-11 {
        margin-left: @width4;
    }

    .start-sm {
        display: flex;
        justify-content: flex-start;
        text-align: start;
    }

    .center-sm {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .end-sm {
        display: flex;
        justify-content: flex-end;
        text-align: end;
    }

    .top-sm {
        display: flex;
        align-items: flex-start;
    }

    .middle-sm {
        display: flex;
        align-items: center;
    }

    .bottom-sm {
        display: flex;
        align-items: flex-end;
    }

    .around-sm {
        display: flex;
        justify-content: space-around;
    }

    .between-sm {
        display: flex;
        justify-content: space-between;
    }

    .first-sm {
        display: flex;
        order: -1;
    }

    .last-sm {
        display: flex;
        order: 1;
    }
}

@media @screen-md {
    .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-offset-0, .col-md-offset-1, .col-md-offset-2, .col-md-offset-3, .col-md-offset-4, .col-md-offset-5, .col-md-offset-6, .col-md-offset-7, .col-md-offset-8, .col-md-offset-9, .col-md-offset-10, .col-md-offset-11, .col-md-offset-12  {
        flex: 0 0 auto;
        padding-left: @half-gutter-width;
        padding-right: @half-gutter-width;
    }

    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: @width11;
    }

    .col-md-1 {
        flex-basis: @width10;
        max-width: @width10;
    }

    .col-md-2 {
        flex-basis: @width7;
        max-width: @width7;
    }

    .col-md-3 {
        flex-basis: @width13;
        max-width: @width13;
    }

    .col-md-4 {
        flex-basis: @width8;
        max-width: @width8;
    }

    .col-md-5 {
        flex-basis: @width9;
        max-width: @width9;
    }

    .col-md-6 {
        flex-basis: @width12;
        max-width: @width12;
    }

    .col-md-7 {
        flex-basis: @width6;
        max-width: @width6;
    }

    .col-md-8 {
        flex-basis: @width5;
        max-width: @width5;
    }

    .col-md-9 {
        flex-basis: @width14;
        max-width: @width14;
    }

    .col-md-10 {
        flex-basis: @width3;
        max-width: @width3;
    }

    .col-md-11 {
        flex-basis: @width4;
        max-width: @width4;
    }

    .col-md-12 {
        flex-basis: @width11;
        max-width: @width11;
    }

    .col-md-offset-0 {
        margin-left: 0;
    }

    .col-md-offset-1 {
        margin-left: @width10;
    }

    .col-md-offset-2 {
        margin-left: @width7;
    }

    .col-md-offset-3 {
        margin-left: @width13;
    }

    .col-md-offset-4 {
        margin-left: @width8;
    }

    .col-md-offset-5 {
        margin-left: @width9;
    }

    .col-md-offset-6 {
        margin-left: @width12;
    }

    .col-md-offset-7 {
        margin-left: @width6;
    }

    .col-md-offset-8 {
        margin-left: @width5;
    }

    .col-md-offset-9 {
        margin-left: @width14;
    }

    .col-md-offset-10 {
        margin-left: @width3;
    }

    .col-md-offset-11 {
        margin-left: @width4;
    }

    .start-md {
        display: flex;
        justify-content: flex-start;
        text-align: start;
    }

    .center-md {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .end-md {
        display: flex;
        justify-content: flex-end;
        text-align: end;
    }

    .top-md {
        display: flex;
        align-items: flex-start;
    }

    .middle-md {
        display: flex;
        align-items: center;
    }

    .bottom-md {
        display: flex;
        align-items: flex-end;
    }

    .around-md {
        display: flex;
        justify-content: space-around;
    }

    .between-md {
        display: flex;
        justify-content: space-between;
    }

    .first-md {
        display: flex;
        order: -1;
    }

    .last-md {
        display: flex;
        order: 1;
    }
}

@media @screen-lg {
    .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-offset-0, .col-lg-offset-1, .col-lg-offset-2, .col-lg-offset-3, .col-lg-offset-4, .col-lg-offset-5, .col-lg-offset-6, .col-lg-offset-7, .col-lg-offset-8, .col-lg-offset-9, .col-lg-offset-10, .col-lg-offset-11, .col-lg-offset-12  {
        flex: 0 0 auto;
        padding-left: @half-gutter-width;
        padding-right: @half-gutter-width;
    }

    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: @width11;
    }

    .col-lg-1 {
        flex-basis: @width10;
        max-width: @width10;
    }

    .col-lg-2 {
        flex-basis: @width7;
        max-width: @width7;
    }

    .col-lg-3 {
        flex-basis: @width13;
        max-width: @width13;
    }

    .col-lg-4 {
        flex-basis: @width8;
        max-width: @width8;
    }

    .col-lg-5 {
        flex-basis: @width9;
        max-width: @width9;
    }

    .col-lg-6 {
        flex-basis: @width12;
        max-width: @width12;
    }

    .col-lg-7 {
        flex-basis: @width6;
        max-width: @width6;
    }

    .col-lg-8 {
        flex-basis: @width5;
        max-width: @width5;
    }

    .col-lg-9 {
        flex-basis: @width14;
        max-width: @width14;
    }

    .col-lg-10 {
        flex-basis: @width3;
        max-width: @width3;
    }

    .col-lg-11 {
        flex-basis: @width4;
        max-width: @width4;
    }

    .col-lg-12 {
        flex-basis: @width11;
        max-width: @width11;
    }

    .col-lg-offset-0 {
        margin-left: 0;
    }

    .col-lg-offset-1 {
        margin-left: @width10;
    }

    .col-lg-offset-2 {
        margin-left: @width7;
    }

    .col-lg-offset-3 {
        margin-left: @width13;
    }

    .col-lg-offset-4 {
        margin-left: @width8;
    }

    .col-lg-offset-5 {
        margin-left: @width9;
    }

    .col-lg-offset-6 {
        margin-left: @width12;
    }

    .col-lg-offset-7 {
        margin-left: @width6;
    }

    .col-lg-offset-8 {
        margin-left: @width5;
    }

    .col-lg-offset-9 {
        margin-left: @width14;
    }

    .col-lg-offset-10 {
        margin-left: @width3;
    }

    .col-lg-offset-11 {
        margin-left: @width4;
    }

    .start-lg {
        display: flex;
        justify-content: flex-start;
        text-align: start;
    }

    .center-lg {
        display: flex;
        justify-content: center;
        text-align: center;
    }

    .end-lg {
        display: flex;
        justify-content: flex-end;
        text-align: end;
    }

    .top-lg {
        display: flex;
        align-items: flex-start;
    }

    .middle-lg {
        display: flex;
        align-items: center;
    }

    .bottom-lg {
        display: flex;
        align-items: flex-end;
    }

    .around-lg {
        display: flex;
        justify-content: space-around;
    }

    .between-lg {
        display: flex;
        justify-content: space-between;
    }

    .first-lg {
        display: flex;
        order: -1;
    }

    .last-lg {
        display: flex;
        order: 1;
    }
}
