



//
// Input - Field
//

.input-field {
    margin: 0 0 4rem 0;

    input, select.browser-default {
        margin: 0;
        color: @color-black;
        height: 4.8rem;
        font-size: @font-base-button;
        box-sizing: border-box;
    }

    select.browser-default {
        -webkit-appearance: none;
        -moz-appearance: none;
        background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAMCAYAAABSgIzaAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAyJpVFh0WE1MOmNvbS5hZG9iZS54bXAAAAAAADw/eHBhY2tldCBiZWdpbj0i77u/IiBpZD0iVzVNME1wQ2VoaUh6cmVTek5UY3prYzlkIj8+IDx4OnhtcG1ldGEgeG1sbnM6eD0iYWRvYmU6bnM6bWV0YS8iIHg6eG1wdGs9IkFkb2JlIFhNUCBDb3JlIDUuMC1jMDYwIDYxLjEzNDc3NywgMjAxMC8wMi8xMi0xNzozMjowMCAgICAgICAgIj4gPHJkZjpSREYgeG1sbnM6cmRmPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5LzAyLzIyLXJkZi1zeW50YXgtbnMjIj4gPHJkZjpEZXNjcmlwdGlvbiByZGY6YWJvdXQ9IiIgeG1sbnM6eG1wPSJodHRwOi8vbnMuYWRvYmUuY29tL3hhcC8xLjAvIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtcDpDcmVhdG9yVG9vbD0iQWRvYmUgUGhvdG9zaG9wIENTNSBNYWNpbnRvc2giIHhtcE1NOkluc3RhbmNlSUQ9InhtcC5paWQ6NDZFNDEwNjlGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiIHhtcE1NOkRvY3VtZW50SUQ9InhtcC5kaWQ6NDZFNDEwNkFGNzFEMTFFMkJEQ0VDRTM1N0RCMzMyMkIiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0NkU0MTA2N0Y3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0NkU0MTA2OEY3MUQxMUUyQkRDRUNFMzU3REIzMzIyQiIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/PuGsgwQAAAA5SURBVHjaYvz//z8DOYCJgUxAf42MQIzTk0D/M+KzkRGPoQSdykiKJrBGpOhgJFYTWNEIiEeAAAMAzNENEOH+do8AAAAASUVORK5CYII=);
        background-repeat: no-repeat;
        background-position: 100% 50%;
        padding-right: 15px;
    }
    /* label color */
    label {
        color: @color-black__60;
        font-size: @font-base;
        line-height: 1;
        left: 0;
        transform: translateY(-140%);
    }

    /* label color */
    input[type=email]+label:after,
    input[type=password]+label:after,
    input[type=text]+label:after {
        top: 4.8rem;
        left: 0;
    }

    /* label color */
    label.active {
        font-size: 1.6rem;
    }
    /* label focus color */
    input[type=email]:focus + label,
    input[type=password]:focus + label,
    input[type=text]:focus + label {
        color: @color-primary;
        font-size: 1.2rem;
    }
    /* valid color */
    input[type=email].valid,
    input[type=password].valid,
    input[type=text].valid {
        border-bottom: 1px solid #000;
        box-shadow: 0 1px 0 0 #000;
    }
    /* label underline focus color */
    input[type=email]:focus,
    input[type=password]:focus,
    input[type=text]:focus {
        border-bottom: 1px solid @color-primary;
        box-shadow: 0 1px 0 0 @color-primary;
    }
    /* invalid color */
    /*
    input[type=email].invalid
    input[type=text].invalid {
        border-bottom: 1px solid @color-primary;
        box-shadow: 0 1px 0 0 @color-primary;
    }
    */
    /* icon prefix focus color */
    .prefix.active {
        color: @color-primary;
    }

    &.___with-icon {
        input {
            padding-left: 2.5rem;
        }
        label {
            padding-left: 2.5rem;
        }
    }
}

.input-field__icon {
    position: absolute;
    top: 0.2rem; left: 0;
    font-size: 1.6rem;

    :focus + label + & {
        color: @color-primary;
    }
}

//
// Input - Field ___white
//

.input-field.___white {

    input {
        margin: 0 0 3rem 0;
        color: @color-white;
        height: 3.4rem;
        font-size: @font-base;
    }

    /* label color */
    label {
        color: @color-black;
        font-size: @font-base;
    }

    /* label color */
    input[type=email]+label:after,
    input[type=password]+label:after,
    input[type=text]+label:after {
        top: 4.8rem;
    }

    /* label color */
    label.active {
        font-size: 1.2rem;
    }
    /* label focus color */
    input[type=email]:focus + label,
    input[type=password]:focus + label,
    input[type=text]:focus + label {
        color: @color-primary;
        font-size: 1.2rem;
    }
    /* valid color */
    input[type=email].valid,
    input[type=password].valid,
    input[type=text].valid {
        border-bottom: 1px solid #fff;
        box-shadow: 0 1px 0 0 #fff;
    }
    /* label underline focus color */
    input[type=email]:focus,
    input[type=password]:focus,
    input[type=text]:focus {
        border-bottom: 1px solid @color-primary;
        box-shadow: 0 1px 0 0 @color-primary;
    }
    /* invalid color */
    /*
    input[type=email].invalid
    input[type=text].invalid {
        border-bottom: 1px solid @color-primary;
        box-shadow: 0 1px 0 0 @color-primary;
    }
    */
    /* icon prefix focus color */
    .prefix.active {
        color: @color-primary;
    }
}


//
// Input - Checkbox
//

[type="checkbox"]+label {
    padding-left: 3rem;
    color: @color-black__60;
    font-size: @font-medium;
    height: 2.2rem;
    line-height: 2.2rem;
}

[type="checkbox"]:checked+label {
    color: @color-black;
}


// CHECK
[type="checkbox"].filled-in:checked+label:before {
    border-right-color: @color-black;
    border-bottom-color: @color-black;
    width: 0.8rem;
    height: 1.3rem;
}

// BOX
[type="checkbox"].filled-in+label:after,
[type="checkbox"].filled-in:checked+label:after,
[type="checkbox"].filled-in:not(:checked)+label:after {
    height: 2rem;
    width: 2rem;
}
// BOX:hover
[type="checkbox"].filled-in:hover+label:hover:after,
[type="checkbox"].filled-in+label:hover:after {
    border-color: @color-grey__40;
}
// BOX:checked
[type="checkbox"].filled-in:checked+label:after {
    background: @color-white;
    border-color: @color-grey__40;
}

// badge
span.badge{
    border-radius: @border-radius;
    padding:0 0.6rem;
    font-size:1.4rem;
    line-height:3.2rem;
    height:3.2rem;
    color:@color-white;

    &.___tag {
        float: none;
        display: inline-block;
        margin: 0 0.4rem 0.4rem;
    }

    svg {
        width: 1.6rem;
        height: 1.6rem;
        fill: currentColor;
        vertical-align: middle;
        margin-right: 0.3rem;
    }
}
    .input-field .badge {
        position: absolute;
        right: 0;
        bottom: 0.8rem;
    }