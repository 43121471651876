.page-layout {
    height: 100%;

    @media @screen-sm-max {
        overflow-y: scroll;

        .___open-overlay & {
            overflow-y: hidden;
        }
    }

    @media @screen-sm {
        display: flex;
        overflow: hidden;
    }
}
