*,
*::before,
*::after {
    box-sizing: inherit;
}

*:focus {
    // remove outline from all focused elements
    outline: none;
}

html {
    //Set the base box-sizing that will be inherited by all other elements
    box-sizing: border-box;

    // Set the base font size for rem sizes
    font-size: 10px;

    // Add cross-browser antialiasing
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    // Other base styling
    text-rendering: auto;
    -webkit-text-size-adjust: 100%;
    position: relative;
    height: 100%;
    margin: 0;

    @media @screen-xl {
        // font-size: 12px;
    }
}

body {
    position: relative;
    width: 100%;
    height: 100%;
    margin: 0;
    background-color: @color-grey__10;
    font-size: 1.6rem;
    line-height: 1.4;
    color: @color-black;
    font-family: @font;
    font-weight: 400;
}

img {
    max-width: 100%;
    border: none;
}

ul {
    list-style: none;
    margin: 0;
    padding: 0;
}

a {
    color: @color-primary;
    text-decoration: none;
    &:hover {
        color: @color-tertiary;
    }
}
