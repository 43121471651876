.dashboard-table {
  td {
    vertical-align: top;
  }
  .registration-data {
    display: flex;
    margin: 1rem 0;

    .site-icon {
      width: 40px;
      min-width: 40px;
      padding-top: .5rem;

      .site-icon-image {
        width: 30px;
        height: auto;
        line-height: 44px;
        max-width: 100%;
      }
    }

    .site-info--title {
      color: @color-black;
    }

    .site-info--description {
      color: @color-black__60;
    }

  }

  .site-member-since {
    min-width: 100px;
    padding-top: 1rem;
  }
}
