.button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 4.4rem;
  line-height: 1;
  margin: 0 0 @button-margin-bottom 0;
  padding: 0 1.6rem;
  background-color: @color-primary;
  font-size: @font-base-button;
  font-weight: 400;
  color: @color-white;
  text-decoration: none;
  border: none;
  border-radius: @border-radius;
  cursor: pointer;
  white-space: nowrap;
  user-select: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  text-align: center;
  position: relative;
  overflow: hidden;
  outline-color: black;

  &:focus {
    background-color: @color-primary;
  }

  &:hover {
    background-color: @color-tertiary;
  }

  /* Needed to lay transparent :hover/:active background on top of original background */

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.___flexbuttons {
  display: flex;
  flex-wrap: wrap;
  justify-content: stretch;
  margin-right: -10px !important;

  .button {
    flex: 1 1 0;
    margin-right: 10px;
  }
}

.button.___stretch {
  display: flex;
  width: 100%;
}

.button.___icon,
.button.___fullspan {
  text-align: left;
}

.button.___outline {
  background-color: @color-white;
  border: 1px solid @color-grey__40;
  outline-color: @color-primary;

  &:hover:after {
    background-color: @color-hover;
  }

  &:active:after {
    background-color: @color-active;
  }

  &.___active {
    border-color: @color-primary;
    color: @color-primary;
  }
}

.button.___outline.___black {
  border: 1px solid @color-grey__75;
  color: @color-grey__75;
}

.button.___white {
  background-color: @color-white;
  color: @color-black__60;
  outline-color: @color-primary;

  &:hover:after {
    background-color: @color-hover;
  }

  &:active:after {
    background-color: @color-active;
  }
}

.button.___clear {
  background-color: transparent;
  color: @color-primary;
  outline-color: @color-primary;

  &:hover:after {
    background-color: @color-hover;
  }

  &:active:after {
    background-color: @color-active;
  }
}

.button.___error {
  background-color: @color-white;
  color: @color-red;
  outline-color: @color-primary;

  &:hover:after {
    background-color: @color-hover;
  }

  &:active:after {
    background-color: @color-active;
  }
}

.button.___round {
  width: 5rem;
  padding: 0;
  background-color: @color-grey__20;
  border-radius: 50%;
}

.button.os-apple {
  .icon(os-apple);

  &:before {
    font-size: 2.4rem;
    margin: 0 1rem 0.4rem 0;
  }
}

.button.os-android {
  .icon(os-android);

  &:before {
    font-size: 2.4rem;
    margin: 0 1rem 0.2rem 0;
  }
}

.button-description {
  margin-top: -(@button-margin-bottom/2);
  margin-bottom: @button-margin-bottom;
}