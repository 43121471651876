.navigation {
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: @color-primary;
    color: @color-white;
    overflow: hidden;
    z-index: 2;

    @media @screen-sm-max {
        width: 100%;
        height: 7rem;
        transition: height @trans-normal;

        .___open-nav & {
            height: 100%;
        }

        .___sticky-nav & {
            position: fixed;
        }
    }

    @media @screen-sm {
        width: 28rem;
        height: 100%;
        padding: 4rem;
    }
}

.mobile-navigation__bar {
    @media @screen-sm-max {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 7rem;
    }
}

.mobile-navigation__hamburger {
    position: relative;
    padding: 2rem;
    .icon(hamburger);
    .icon(close, after);
    cursor: pointer;

    &:before {
        width: 2rem;
        font-size: 1.4rem;
        transition: opacity @trans-normal, transform @trans-normal;

        .___open-nav & {
            opacity: 0;
            transform: rotate(180deg);
        }
    }

    &:after {
        position: absolute;
        left: 2rem;
        width: 2rem;
        font-size: 2rem;
        opacity: 0;
        transform: rotate(-180deg);
        transition: opacity @trans-normal, transform @trans-normal;

        .___open-nav & {
            opacity: 1;
            transform: rotate(0deg);
        }
    }

    @media @screen-sm {
        display: none;
    }
}

.mobile-navigation__spacer {
    width: 6rem;
}

.navigation__logo {
    display: block;
    cursor: pointer;

    svg {
        display: block;
    }

    #background,
    #lines,
    #text {
        transition: fill @trans-fast;
    }

    &:hover {
        #lines {
            fill: @color-primary;
        }
    }

    @media @screen-sm-max {
        transition: opacity @trans-normal;

        .___open-nav & {
            opacity: 0;
        }
    }
}

.navigation__menu {
    padding: 5rem 0 0 0;

    @media @screen-sm-max {
        padding: 0;
        opacity: 0;
        background-color: @color-primary;
        transition: opacity @trans-normal;

        .___open-nav & {
            opacity: 1;
        }
    }
}

.navigation__link {
    display: block;
    color: @color-white;
    text-decoration: none;
    padding: 1rem 0;
    cursor: pointer;
    user-select: none;
    transition: color @trans-fast;

    @media @screen-sm-max {
        font-size: 2rem;
        padding: 1rem 2rem;
    }

    &:hover {
        color: @color-blue;
    }

    &.___is-active {
        color: @color-black;
    }
}


.navigation__account {
    @media @screen-sm-max {
        padding: 0 2rem;

        .button {
            opacity: 0;
            transition: background-color @trans-fast, color @trans-fast, box-shadow @trans-fast, opacity @trans-normal;

            .___open-nav & {
                opacity: 1;

                &:first-child {
                    transition-delay: 0.25s;
                }
                &:last-child {
                    transition-delay: 0.3s;
                }
            }
        }
    }
}

.navigation__languages {
    padding: 2rem 0 0 0;

    @media @screen-sm-max {
        position: absolute;
        top: 0.5rem;
        right: 2rem;
        opacity: 0;
        transition: opacity @trans-normal;

        .___open-nav & {
            opacity: 1;
        }
    }
}

.navigation__language {
    font-size: @font-small;
    color: @color-white;
    cursor: pointer;
    transition: color @trans-fast;

    &:not(.___is-active):hover {
        color: @color-blue;
    }

    &.___is-active {
        font-weight: bold;
        cursor: default;
    }
}
