p {
  margin: 0 0 3rem 0;

  &:last-child {
    margin-bottom: 0;
  }
}

h1, h2, h3, h4, h5, h6, strong {
    margin: 0;
    font-weight: 400;
}

.textcolor_primary { color: @color-black; }
.textcolor_secondary { color: @color-black__60; }

.paragraph a {
  color: @color-primary;
  text-decoration: underline;
}