.slide {
    position: relative;
    height: 100%;
    overflow: hidden;

    @media @screen-sm-max {
        padding: 2rem;
    }
}

.slide__content {
    @media @screen-sm {
        display: flex;
        align-items: center;
        position: absolute;
        left: 0;
        height: 100%;
        padding: 0 1rem 0 3rem;

        &.___is-animated {
            transition: 0.3s ease-out;
        }
    }
}

.slide-buttons {
    display: none;

    @media @screen-sm {
        display: flex;
        position: absolute;
        top: 3rem;
        right: 3rem;
        z-index: 1;
    }
}

.slide-button__prev,
.slide-button__next {
    text-align: center;
    transition: opacity @trans-fast;

    &:hover:before {
        color: @color-tertiary;
    }

    &:before {
        font-size: 2rem;
        color: @color-primary;
        line-height: inherit;
        transition: color @trans-fast;
    }

    &.___is-disabled {
        cursor: default;

        &:before {
            color: @color-grey__40;
        }
    }

    &.___is-active {
        opacity: 1;
        cursor: pointer;
    }
}

.slide-button__prev {
    margin: 0 1rem 0 0;
    .icon(chevron-left);
    opacity: 0;
    cursor: default;
}

.slide-button__next {
    margin: 0;
    .icon(chevron-right);
}
