// Colors
// -------------------------------

@color-primary:                     #0771f2;
@color-secondary:                   #90caf9;
@color-tertiary:                    #0665d9;

@color-red:                         #EB0000;
@color-blue:                        #0771f2;
@color-orange:                      #ff9911;
@color-green:                       #00892D;
@color-red__10:                     #fcc6c2;
@color-blue__10:                    #dce8f6;
@color-green__10:                   #e4f3e5;


// Black & white
@color-black__60:                   rgba(0,0,0,.55);

@color-black:                       #000;
@color-white:                       #fff;


// Grey
@color-grey__10:                    #FAFAFA;
@color-grey__20:                    #f5f5f5;
@color-grey__30:                    #EAEAEA;
@color-grey__40:                    #BFBFBF;
@color-grey__50:                    #535353;
@color-grey__75:                    #343434;

@color-divider:                     #DDDDDD;
@color-grey:                        #949494;
@color-icon-grey:                   rgba(0,0,0,.42);

@color-hover:                       rgba(0,0,0,.03);
@color-active:                       rgba(0,0,0,.06);


// Fonts
// -------------------------------

@font:                              'Roboto', sans-serif;

@font-small:                        1.4rem;
@font-medium:                       1.6rem;
@font-base:                         1.6rem;
@font-base-button:                  1.8rem;


// Border radius
// -------------------------------

@border-radius:              0.4rem;


// Box shadows
// -------------------------------

.box-shadow() {
    box-shadow:                     0 0.2rem 2rem 0 rgba(0, 0, 0, 0.1);
}

// Text shadows
// -------------------------------

@text-shadow:                       0 2px 5px rgba(0, 0, 0, 0.25);


// Transitions
// -------------------------------

@trans-fast:                        0.2s cubic-bezier(.4, 0, 0.2, 1);
@trans-normal:                      0.4s cubic-bezier(.4, 0, 0.2, 1);


//
// Breakpoints
// --------------------------------------------------

// Min widths
@screen-xs:                   ~'(min-width: 400px)';
@screen-sm:                   ~'(min-width: 680px)';
@screen-md:                   ~'(min-width: 768px)';
@screen-lg:                   ~'(min-width: 1024px)';
@screen-xl:                   ~'(min-width: 1681px)';

// Max widths
@screen-xs-max:               ~'(max-width: 399px)';
@screen-sm-max:               ~'(max-width: 679px)';
@screen-md-max:               ~'(max-width: 767px)';
@screen-lg-max:               ~'(max-width: 1023px)';
@screen-xl-max:               ~'(max-width: 1680px)';

//
// Button padding
// --------------------------------------------------
@button-margin-bottom: 1.75rem;