.card {
    position: relative;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;
    width: 48rem;
    background-color: @color-white;
    border-radius: @border-radius;
    .box-shadow();

    &.___stretch {
        width: 100%;
    }

    &.___blue-bg {
        background-color: @color-tertiary;
        color: @color-white;
    }

    &.___justify-end {
        justify-content: space-between;
        padding-bottom: 0;

        @media @screen-sm {
            padding-bottom: 2rem;
        }
    }

    @media @screen-sm-max {
        width: 100%;
        margin: 0 0 2rem 0;
        padding: 2rem;

        &.___big {
            min-height: 33rem;
        }
    }

    @media @screen-sm {
        margin: 0 1rem;
        padding: 2.4rem;
    }

    &.___nopadding {
        padding: 0;
    }

    .___addpadding-side {
        padding-left: 2.4rem;
        padding-right: 2.4rem;
    }

    .___addpadding-topbottom {
        padding-top: 2.4rem;
        padding-bottom: 2.4rem;
    }
}

.card__image {
    position: relative;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 25rem;
    padding: 0 0 4rem 0;

    @media @screen-sm-max {
        height: 10rem;
        padding: 0 0 2rem 0;

        img {
            height: 100%;
        }
    }

    img {
        max-height: 100%;
        pointer-events: none;
    }
}

.card__play {
    position: absolute;
    width: 6rem;
    height: 6rem;
    line-height: 6rem;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    background-color: @color-white;
    text-align: center;
    border-radius: 50%;
    cursor: pointer;
    .box-shadow;
    .icon(play);

    &:before {
        line-height: inherit;
        font-size: 2.7rem;
        color: @color-grey__50;
        transition: color @trans-fast;
    }

    &:hover:before {
        color: @color-tertiary;
    }

    @media @screen-sm {
        width: 8rem;
        height: 8rem;
        line-height: 8rem;

        &:before {
            font-size: 3.6rem;
        }
    }
}

.card__title {
    margin-bottom: 2rem;
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
    border-bottom: 1px solid @color-divider;
    color: @color-primary;

    .___blue-bg & {
        color: @color-white;
    }

    .___red & {
        color: @color-red;
    }
    .___blue & {
        color: @color-blue;
    }
    .___orange & {
        color: @color-orange;
    }
    .___green & {
        color: @color-green;
    }

    & + fieldset, & + .account-user__pickavatar, & + form { margin-top: 2rem; }
}

.card__action {
    padding-top: 1rem;
    padding-bottom: 1rem;
    display: flex;

    .button {
        margin: 0;
    }

    > * {
        margin-left: auto !important;
    }
}

h1.card__title {
    font-size: 4rem;
    font-weight: 300;

    @media @screen-sm-max {
        font-size: 2rem;
    }
}

h2.card__title {
    font-size: 2rem;
    font-weight: 400;
}

.card__content {
    flex-grow: 1;
    color: @color-grey;
    line-height: 2.8rem;

    .___justify-end & {
        flex-grow: 0;
    }

    @media @screen-sm-max {
        line-height: 2.4rem;
    }
}

.card__read-more {
    position: absolute;
    bottom: 1.7rem;
    right: 2rem;

    @media @screen-sm {
        bottom: 3.7rem;
        right: 4rem;
    }
}
