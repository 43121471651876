.video-modal {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
    z-index: 3;

    .container {
        position: relative;
        height: 100%;
    }
}

.video-modal__box {
    position: absolute;
    height: 0;
    max-width: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto 2rem;
    padding-bottom: 56.25%;
    z-index: 1;
    background: #000;

    @media @screen-sm {
        margin: auto 4rem;
    }
}

.video-modal__box iframe,
.video-modal__box object,
.video-modal__box embed {
    position: absolute; top: 0; left: 0; width: 100%; height: 100%;
}

.video-modal__overlay {
    background-color: rgba(0, 0, 0, 0.5);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.video-modal__close {
    background-color: @color-white;
    position: absolute;
    top: 0;
    right: 0;
    width: 3rem;
    height: 3rem;
    line-height: 3rem;
    text-align: center;
    border-radius: 50%;
    transform: translate(50%, -50%);
    z-index: 1;
    cursor: pointer;
    display: block;

    .icon(close);

    &:before {
        font-size: 1.3rem;
        line-height: inherit;
        color: @color-grey__50;
        transition: color @trans-fast;
    }

    &:hover:before {
        color: @color-tertiary;
    }
}
