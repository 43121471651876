@font-face {
    font-family: "icons-webfont";
    src: url('../icons/icons-webfont.eot');
    src: url('../icons/icons-webfont.eot?#iefix') format('eot'),
        url('../icons/icons-webfont.woff2') format('woff2'),
        url('../icons/icons-webfont.woff') format('woff'),
        url('../icons/icons-webfont.ttf') format('truetype'),
        url('../icons/icons-webfont.svg#icons-webfont') format('svg');
}

[class^="icon-"],
[class*=" icon-"],
.icon-base-pseudo {
    display: inline-block;
    vertical-align: middle;
    font-family: "icons-webfont";
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-variant: normal;
    font-weight: normal;
    // speak: none; // only necessary if not using the private unicode range (firstGlyph option)
    text-decoration: none;
    text-transform: none;
    line-height: 1;
}

.icon-char(@filename) {
    @arrow-right: "\E001";
    @chevron-left: "\E002";
    @chevron-right: "\E003";
    @chevron-up: "\E004";
    @close: "\E005";
    @hamburger: "\E006";
    @os-android: "\E007";
    @os-apple: "\E008";
    @play: "\E009";
    @search: "\E00A";
    
    content: @@filename;
}

.icon(@filename, @insert: before) {
    @pseudo-selector: ~":@{insert}";

    &@{pseudo-selector} {
        &:extend(.icon-base-pseudo);
        .icon-char(@filename);
    }
}

//
// Icons
//

.icon-arrow-right:before {
    content: "\E001";
}

.icon-chevron-left:before {
    content: "\E002";
}

.icon-chevron-right:before {
    content: "\E003";
}

.icon-chevron-up:before {
    content: "\E004";
}

.icon-close:before {
    content: "\E005";
}

.icon-hamburger:before {
    content: "\E006";
}

.icon-os-android:before {
    content: "\E007";
}

.icon-os-apple:before {
    content: "\E008";
}

.icon-play:before {
    content: "\E009";
}

.icon-search:before {
    content: "\E00A";
}

