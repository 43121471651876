.article {
    h1 {
        font-size: 4.8rem;
        font-weight: 300;
        color: @color-black;

        @media @screen-sm-max {
            font-size: 2rem;
        }
    }
}

.article__to-top {
    position: fixed;
    .icon(chevron-up);
    margin: 0;
    bottom: 3rem;
    right: 3.5rem;
    text-align: center;
    opacity: 0;
    transition: opacity @trans-fast;

    &:before {
        font-size: 1.4rem;
        color: @color-primary;
        line-height: inherit;
        transition: color @trans-fast;
    }

    &.___is-active {
        opacity: 1;
    }
}

.article__header {
    position: relative;
    padding: 4rem;
    background-color: @color-grey__10;

    @media @screen-sm {
        padding: 8rem;
    }
}

.article__image {
    margin: 4rem 0 0 0;
}

.article__body {
    max-width: 84rem;
    margin: 0 auto;
    padding: 4rem 2rem;
    font-weight: 300;

    @media @screen-sm {
        padding: 4rem;
    }
}

.article__intro {
    font-size: 2.4rem;

    @media @screen-sm-max {
        font-size: 1.6rem;
    }
}

.article__block {
    margin: 0 0 3rem 0;
    padding: 3rem 0;
    border-top: 1px solid @color-grey__30;
    border-bottom: 1px solid @color-grey__30;

    span {
        font-size: 3.2rem;
        color: @color-primary;
        font-weight: 400;

        @media @screen-sm-max {
            font-size: 2.4rem;
        }
    }
}
