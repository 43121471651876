.read-more {
    background: none;
    color: @color-grey__50;
    transition: color @trans-fast;
    cursor: pointer;

    &:hover {
        color: @color-tertiary;
    }

    &.___disabled {
        color: @color-grey;
        cursor: default;
    }

    &.___enabled {
        color: @color-primary;
    }
}

.read-more__icon {
    margin: 0 0 0 1rem;
    .icon(arrow-right);

    &:before {
        font-size: 2rem;
    }
}

